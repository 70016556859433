import { doc, onSnapshot, Unsubscribe } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import i18next from 'i18next';
import { Dispatch } from 'redux';

import { firestore, functions } from '..';
import {
  SetProfileRequest,
  SetProfileResponse,
} from '../../server/functions/src/types';
import { Profile } from '../models/profile';
import { loadingHide, loadingShow } from '../store/reducers/loading-reducer';
import {
  profileDataClear,
  profileDataSet,
} from '../store/reducers/profile-reducer';
import { ToastColor, toastShow } from '../store/reducers/toast-reducer';

let profileSub: Unsubscribe | undefined;

export function profileSubscribe(userId: string, dispatch: Dispatch): void {
  const profileDoc = doc(firestore, 'users', userId);
  dispatch(loadingShow());
  profileSub = onSnapshot(
    profileDoc,
    async (doc) => {
      const data = doc.data();
      dispatch(profileDataSet(data));
      dispatch(loadingHide());
    },
    () => {
      dispatch(loadingHide());
      dispatch(
        toastShow(i18next.t('profile.messages.get-error'), ToastColor.ERROR)
      );
    }
  );
}

export function profileUnsubscribe(dispatch: Dispatch): void {
  dispatch(profileDataClear());
  profileSub?.();
}

export const setUserProfile = (profile: Profile) => {
  return (dispatch: Dispatch): void => {
    dispatch(loadingShow());
    httpsCallable<SetProfileRequest, Promise<SetProfileResponse>>(
      functions,
      'setUser'
    )(profile)
      .then(() => {
        dispatch(toastShow(i18next.t('profile.messages.update-success')));
      })
      .catch(() => {
        dispatch(
          toastShow(
            i18next.t('profile.messages.update-error'),
            ToastColor.ERROR
          )
        );
      })
      .finally(() => dispatch(loadingHide()));
  };
};
