import { createSelector } from 'reselect';

import { ChartData } from '../../models/charts';
import { WINE_TYPE } from '../../models/wine';
import { sortObjectPropsToArray } from '../../utils/object-to-sorted-array';
import { ReduxAction, ReduxState } from '../store';
import { createAction } from '../utils';

import { AUTHENTICATION_ACTION_TYPES } from './authentication-reducer';

export interface DashBoard {
  wineTypeCount?: { [key in WINE_TYPE]: number };
  wineYearCount?: { [key: number]: number };
  wineCountryCount?: { [key: string]: number };
  wineRatingCount?: { [key: number]: number };
  wineCount?: number;
}

export interface DashboardReduxState {
  isLoaded: boolean;
  dashboard: DashBoard;
}

export interface DashBoardView {
  wineTypeCount: ChartData[];
  wineYearCount: ChartData[];
  wineCountryCount: ChartData[];
  wineRatingCount: ChartData[];
  wineCount?: number;
}

const INITIAL_STATE = {
  isLoaded: false,
  dashboard: {},
};

const DASHBOARD_ACTION_TYPES = {
  DASHBOARD_DATA_LOADED: 'DASHBOARD_ACTIONS/LOADED',
  DASHBOARD_DATA_ERROR: 'DASHBOARD_ACTIONS/ERROR',
};

export const dashboardSelector = (state: ReduxState): DashboardReduxState =>
  state.dashboard;

export const dashboardDataSelector = createSelector(
  [dashboardSelector],
  ({ dashboard }): DashBoardView => {
    const wineCountryCount = sortObjectPropsToArray(
      dashboard.wineCountryCount || {}
    );

    const wineTypeCount = sortObjectPropsToArray(dashboard.wineTypeCount || {});
    const wineRatingCount = sortObjectPropsToArray(
      dashboard.wineRatingCount || {}
    );
    const wineYearCount = sortObjectPropsToArray(dashboard.wineYearCount || {});

    return {
      wineCountryCount,
      wineTypeCount,
      wineYearCount,
      wineRatingCount,
      wineCount: dashboard.wineCount,
    };
  }
);

export function dashboardDataLoaded(data: DashBoard = {}): ReduxAction {
  return createAction(DASHBOARD_ACTION_TYPES.DASHBOARD_DATA_LOADED, { data });
}

export const dashboardReducer = (
  state = INITIAL_STATE,
  action: ReduxAction
): DashboardReduxState => {
  switch (action.type) {
    case DASHBOARD_ACTION_TYPES.DASHBOARD_DATA_LOADED:
      return {
        dashboard: action.payload?.data,
        isLoaded: true,
      };
    case AUTHENTICATION_ACTION_TYPES.SIGNED_OUT: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};
