import { ReduxState, ReduxAction } from '../store';
import { createAction } from '../utils';

export interface LoadingReduxState {
  count: number;
}

const INITIAL_STATE: LoadingReduxState = {
  count: 0,
};

export const LOADING_ACTION_TYPES = {
  LOADER_ADD: 'LOADING_ACTION_TYPES/LOADER_ADD',
  LOADER_SUBTRACT: 'LOADING_ACTION_TYPES/LOADER_SUBTRACT',
};

export const isLoadingSelector = (state: ReduxState): boolean =>
  state.loading.count > 0;

export function loadingShow(): ReduxAction {
  return createAction(LOADING_ACTION_TYPES.LOADER_ADD);
}

export function loadingHide(): ReduxAction {
  return createAction(LOADING_ACTION_TYPES.LOADER_SUBTRACT);
}

export const loadingReducer = (
  state = INITIAL_STATE,
  action: ReduxAction
): LoadingReduxState => {
  switch (action.type) {
    case LOADING_ACTION_TYPES.LOADER_ADD:
      return {
        count: state.count >= 0 ? state.count + 1 : 1,
      };
    case LOADING_ACTION_TYPES.LOADER_SUBTRACT:
      return { count: state.count > 0 ? state.count - 1 : 0 };
    default:
      return state;
  }
};
