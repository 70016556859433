export const ROUTE_PATHS = {
  ACOUNT_DELETION_POLICY: '/account-deletion-policy',
  DASHBOARD: '/dashboard',
  PRIVACY_POLICY: '/privacy-policy',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up',
  WINE_EDIT: '/wine-edit',
  WINE_LIST: '/wine-list',
  WINE_VIEW: '/wine-view',
};
