import { createStore, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import {
  authenticationReducer as authentication,
  AuthenticationReduxState,
} from './reducers/authentication-reducer';
import {
  dashboardReducer as dashboard,
  DashboardReduxState,
} from './reducers/dashboard-reducer';
import {
  loadingReducer as loading,
  LoadingReduxState,
} from './reducers/loading-reducer';
import { menuReducer as menu, MenuReduxState } from './reducers/menu-reducer';
import {
  profileReducer as profile,
  ProfileReduxState,
} from './reducers/profile-reducer';
import {
  toastReducer as toast,
  ToastReduxState,
} from './reducers/toast-reducer';
import { wineReducer as wine, WineReduxState } from './reducers/wine-reducer';

export interface ReduxState {
  authentication: AuthenticationReduxState;
  dashboard: DashboardReduxState;
  loading: LoadingReduxState;
  menu: MenuReduxState;
  profile: ProfileReduxState;
  toast: ToastReduxState;
  wine: WineReduxState;
}

export interface ReduxAction {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}

const rootReducer = combineReducers({
  authentication,
  dashboard,
  loading,
  menu,
  profile,
  toast,
  wine,
});

const composeEnhancers = composeWithDevTools({});
export const store = createStore(rootReducer, composeEnhancers());
