import React from 'react';

import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { isAuthenticatedSelector } from '../store/reducers/authentication-reducer';

import { ROUTE_PATHS } from './route-paths';

export type ProtectedRouteProps = {
  outlet: JSX.Element;
};

export const ProtectedRoute = ({
  outlet,
}: ProtectedRouteProps): JSX.Element => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  if (!isAuthenticated) {
    return <Navigate to={ROUTE_PATHS.SIGN_IN} />;
  }

  return outlet;
};
