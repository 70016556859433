import {
  doc,
  DocumentReference,
  onSnapshot,
  Unsubscribe,
} from 'firebase/firestore';
import { Dispatch } from 'redux';

import { firestore } from '..';
import { dashboardDataLoaded } from '../store/reducers/dashboard-reducer';
import { DashBoard } from '../store/reducers/dashboard-reducer';

let dashboardSub: Unsubscribe | undefined;

export function dashboardSubscribe(userId: string, dispatch: Dispatch): void {
  const dashboardDoc = doc(
    firestore,
    'dashboard',
    userId
  ) as DocumentReference<DashBoard>;
  dashboardSub = onSnapshot(dashboardDoc, (doc) => {
    if (doc.exists()) {
      dispatch(dashboardDataLoaded(doc.data()));
    }
  });
}

export function dashboardUnsubscribe(): void {
  dashboardSub?.();
}
