import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface IProps {
  title?: string;
  message: string;
  open: boolean;
  okHandler: () => void;
  cancelHandler?: () => void;
}

export const ConfirmationDialog: React.FC<IProps> = ({
  okHandler,
  cancelHandler,
  title,
  message,
  open,
  ...other
}: IProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      {...other}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent dividers>{message}</DialogContent>
      <DialogActions>
        {cancelHandler && (
          <Button autoFocus onClick={cancelHandler}>
            {t('app.buttons.cancel')}
          </Button>
        )}
        <Button onClick={okHandler}>{t('app.buttons.ok')}</Button>
      </DialogActions>
    </Dialog>
  );
};
