import React from 'react';

import { Alert, Snackbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import {
  resetToast,
  ToastColor,
  toastSelector,
} from '../../store/reducers/toast-reducer';

export const Toast: React.FC = () => {
  // const classes = useStyles();
  const dispatch = useDispatch();
  const {
    isOpen,
    message,
    type = ToastColor.SUCCESS,
  } = useSelector(toastSelector);

  const handleClose = (
    event: Event | React.SyntheticEvent<Element, Event>,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(resetToast());
  };

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert elevation={6} variant="filled" onClose={handleClose} color={type}>
        {message}
      </Alert>
    </Snackbar>
  );
};
