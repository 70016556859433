import { ReduxState, ReduxAction } from '../store';
import { createAction } from '../utils';

export interface MenuReduxState {
  isOpen: boolean;
}

const INITIAL_STATE: MenuReduxState = {
  isOpen: false,
};

export const MENU_ACTION_TYPES = {
  MENU_SHOW: 'MENU_ACTION_TYPES/MENU_SHOW',
  MENU_HIDE: 'MENU_ACTION_TYPES/MENU_HIDE',
  MENU_TOGGLE: 'MENU_ACTION_TYPES/MENU_TOGGLE',
};

export const isMenuOpenSelector = (state: ReduxState): boolean =>
  state.menu.isOpen;

export function menuHide(): ReduxAction {
  return createAction(MENU_ACTION_TYPES.MENU_HIDE);
}

export function menuShow(): ReduxAction {
  return createAction(MENU_ACTION_TYPES.MENU_SHOW);
}

export function menuToggle(): ReduxAction {
  return createAction(MENU_ACTION_TYPES.MENU_TOGGLE);
}

export const menuReducer = (
  state = INITIAL_STATE,
  action: ReduxAction
): MenuReduxState => {
  switch (action.type) {
    case MENU_ACTION_TYPES.MENU_SHOW:
      return {
        ...state,
        isOpen: true,
      };
    case MENU_ACTION_TYPES.MENU_HIDE:
      return {
        ...state,
        isOpen: false,
      };
    case MENU_ACTION_TYPES.MENU_TOGGLE:
      return {
        ...state,
        isOpen: !state.isOpen,
      };
    default:
      return state;
  }
};
