import { Profile } from '../../models/profile';
import { ReduxState, ReduxAction } from '../store';
import { createAction } from '../utils';

import { AUTHENTICATION_ACTION_TYPES } from './authentication-reducer';

export interface ProfileReduxState {
  profile: Profile;
  isLoading: boolean;
}

const INITIAL_STATE: ProfileReduxState = {
  isLoading: true,
  profile: {
    username: '',
    image: '',
  },
};

const PROFILE_ACTION_TYPES = {
  PROFILE_DATA_CLEAR: 'PROFILE_ACTIONS/CLEAR',
  PROFILE_DATA_ERROR: 'PROFILE_ACTIONS/ERROR',
  PROFILE_DATA_SET: 'PROFILE_ACTIONS/SET',
  SET_IMAGES: 'PROFILE_ACTIONS/SET_IMAGES',
};

export const profileSelector = (state: ReduxState): ProfileReduxState =>
  state.profile || {};

export const profileIsLoadingSelector = (state: ReduxState): boolean =>
  state.profile.isLoading;

export function profileDataClear(): ReduxAction {
  return createAction(PROFILE_ACTION_TYPES.PROFILE_DATA_CLEAR);
}

export function profileDataSet(data: Profile = {}): ReduxAction {
  return createAction(PROFILE_ACTION_TYPES.PROFILE_DATA_SET, { data });
}

export function profileSetImage(data: string): ReduxAction {
  return createAction(PROFILE_ACTION_TYPES.SET_IMAGES, { data });
}

export const profileReducer = (
  state = INITIAL_STATE,
  action: ReduxAction
): ProfileReduxState => {
  switch (action.type) {
    case PROFILE_ACTION_TYPES.PROFILE_DATA_CLEAR: {
      console.log(INITIAL_STATE);
      return {
        ...INITIAL_STATE,
      };
    }
    case PROFILE_ACTION_TYPES.SET_IMAGES: {
      return {
        ...state,
        profile: {
          ...state.profile,
          image: action.payload?.data,
        },
      };
    }
    case PROFILE_ACTION_TYPES.PROFILE_DATA_SET:
      return {
        ...state,
        isLoading: false,
        profile: {
          ...action.payload?.data,
        },
      };
    case AUTHENTICATION_ACTION_TYPES.SIGNED_OUT: {
      return { ...INITIAL_STATE };
    }
    default:
      return state;
  }
};
