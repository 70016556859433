import { AlertColor } from '@mui/material';

import { ReduxState, ReduxAction } from '../store';
import { createAction } from '../utils';

export enum ToastColor {
  ALERT = 'alert',
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export interface ToastReduxState {
  isOpen: boolean;
  type?: AlertColor;
  message: string;
}

const INITIAL_STATE: ToastReduxState = {
  isOpen: false,
  message: '',
  type: undefined,
};

export const TOAST_ACTION_TYPES = {
  SET_TOAST: 'TOAST_ACTION_TYPES/SET_TOAST',
  RESET_TOAST: 'TOAST_ACTION_TYPES/RESET_TOAST',
};

export const toastSelector = (state: ReduxState): ToastReduxState =>
  state.toast;

export function toastShow(message: string, type?: AlertColor): ReduxAction {
  return createAction(TOAST_ACTION_TYPES.SET_TOAST, {
    type,
    message,
  });
}

export function resetToast(): ReduxAction {
  return createAction(TOAST_ACTION_TYPES.RESET_TOAST);
}

export const toastReducer = (
  state = INITIAL_STATE,
  action: ReduxAction
): ToastReduxState => {
  switch (action.type) {
    case TOAST_ACTION_TYPES.SET_TOAST: {
      const { type, message } = action.payload;

      return {
        isOpen: true,
        type,
        message,
      };
    }
    case TOAST_ACTION_TYPES.RESET_TOAST:
      return INITIAL_STATE;
    default:
      return state;
  }
};
