import { UserInfo } from 'firebase/auth';

import { ReduxState, ReduxAction } from '../store';
import { createAction } from '../utils';

export interface AuthenticationReduxState {
  isAuthenticated: boolean;
  isAuthenticationChecked: boolean;
  id?: string;
}

const INITIAL_STATE: AuthenticationReduxState = {
  isAuthenticated: false,
  isAuthenticationChecked: false,
};

export const AUTHENTICATION_ACTION_TYPES = {
  SIGNED_IN: 'AUTHENTICATION_ACTIONS/SIGNED_IN',
  SIGNED_OUT: 'AUTHENTICATION_ACTIONS/SIGNED_OUT',
};

export const authenticationSelector = (
  state: ReduxState
): AuthenticationReduxState => state.authentication;

export const isAuthenticatedSelector = (state: ReduxState): boolean =>
  authenticationSelector(state).isAuthenticated;

export const currentUserIdSelector = (state: ReduxState): string | undefined =>
  authenticationSelector(state).id;

export function authenticationSignedIn(user: UserInfo): ReduxAction {
  return createAction(AUTHENTICATION_ACTION_TYPES.SIGNED_IN, { id: user.uid });
}

export function authenticationSignedOut(): ReduxAction {
  return createAction(AUTHENTICATION_ACTION_TYPES.SIGNED_OUT);
}

export const authenticationReducer = (
  state = INITIAL_STATE,
  action: ReduxAction
): AuthenticationReduxState => {
  switch (action.type) {
    case AUTHENTICATION_ACTION_TYPES.SIGNED_IN:
      return {
        ...state,
        isAuthenticated: true,
        id: action?.payload?.id,
        isAuthenticationChecked: true,
      };
    case AUTHENTICATION_ACTION_TYPES.SIGNED_OUT:
      return { isAuthenticated: false, isAuthenticationChecked: true };
    default:
      return state;
  }
};
