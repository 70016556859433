import React from 'react';

import { Backdrop, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';

import { isLoadingSelector } from '../../store/reducers/loading-reducer';

export const LoadingSpinner: React.FC = () => {
  const isLoading = useSelector(isLoadingSelector);

  return (
    <Backdrop
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1000 }}
      open={isLoading}
    >
      {isLoading && <CircularProgress />}
    </Backdrop>
  );
};
