import React from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faHome,
  faUserEdit,
  faSignOut,
  faWineBottle,
  faCog,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { ROUTE_PATHS } from '../../routing/route-paths';
import { logout } from '../../services/authentication-service';
import { getImagePathWithDomain } from '../../services/image-service';
import { menuHide } from '../../store/reducers/menu-reducer';
import { profileSelector } from '../../store/reducers/profile-reducer';

import { menuAvatarStyle, menuHeaderContainerStyle } from './styles';

interface AppPage {
  url: string;
  icon: IconProp;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: 'app.menu.dashboard',
    url: ROUTE_PATHS.DASHBOARD,
    icon: faHome,
  },
  {
    title: 'app.menu.wine-list',
    url: ROUTE_PATHS.WINE_LIST,
    icon: faWineBottle,
  },
  {
    title: 'app.menu.profile',
    url: ROUTE_PATHS.PROFILE,
    icon: faUserEdit,
  },
  {
    title: 'app.menu.settings',
    url: ROUTE_PATHS.SETTINGS,
    icon: faCog,
  },
];

export const Menu: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { profile } = useSelector(profileSelector);
  const closeMenu = () => dispatch(menuHide());

  const handleLogout = async () => {
    await logout(dispatch);
    closeMenu();
  };

  return (
    <Box>
      <Box sx={menuHeaderContainerStyle}>
        <Avatar
          src={getImagePathWithDomain(profile?.image)}
          sx={menuAvatarStyle}
        />
        <Typography mt={2}>{profile.username}</Typography>
      </Box>
      <Box>
        <List>
          {appPages.map((appPage, index) => (
            <ListItemButton
              key={index}
              selected={location.pathname === appPage.url}
              component={RouterLink}
              to={appPage.url}
              onClick={closeMenu}
            >
              <ListItemIcon>
                <FontAwesomeIcon pull="left" icon={appPage.icon} />
              </ListItemIcon>
              <ListItemText primary={t(appPage.title)} />
            </ListItemButton>
          ))}
        </List>

        <Divider />
        <ListItemButton onClick={handleLogout} color="error">
          <ListItemIcon>
            <FontAwesomeIcon pull="left" icon={faSignOut} />
          </ListItemIcon>
          <ListItemText primary={t('auth.buttons.sign-out')} />
        </ListItemButton>
      </Box>
    </Box>
  );
};
