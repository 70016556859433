import { httpsCallable } from 'firebase/functions';
import i18next from 'i18next';
import { Dispatch } from 'redux';

import { functions } from '..';
import { loadingShow, loadingHide } from '../store/reducers/loading-reducer';
import { profileSetImage } from '../store/reducers/profile-reducer';
import { ToastColor, toastShow } from '../store/reducers/toast-reducer';
import { wineSetImage } from '../store/reducers/wine-reducer';

let imageHostUrl = 'https://ik.imagekit.io/f20km6gxi/';

if (process.env.NODE_ENV === 'development') {
  imageHostUrl = 'http://localhost:9199/';
}

export type ImageData = string | ArrayBuffer | null;

interface UploadImageResponse {
  image: string;
}

interface UploadWineImageParams {
  wineId: string;
  image: ImageData;
}

interface UploadProfileImageParams {
  image: ImageData;
}

export const uploadProfileImage = (image: ImageData) => {
  return (dispatch: Dispatch): Promise<unknown> => {
    dispatch(loadingShow());

    const remoteUploadProfileImage = httpsCallable<
      UploadProfileImageParams,
      UploadImageResponse
    >(functions, 'uploadProfileImage');

    return remoteUploadProfileImage({ image })
      .then((result) => {
        dispatch(profileSetImage(result.data.image));
        dispatch(toastShow(i18next.t('images.messages.upload-image-success')));
      })
      .catch(() => {
        dispatch(
          toastShow(
            i18next.t('images.messages.upload-image-error'),
            ToastColor.ERROR
          )
        );
      })
      .finally(() => {
        dispatch(loadingHide());
      });
  };
};

export const uploadWineImage = (image: ImageData, wineId: string) => {
  return (dispatch: Dispatch): Promise<UploadImageResponse | void> => {
    dispatch(loadingShow());

    const remoteUploadWineImage = httpsCallable<
      UploadWineImageParams,
      UploadImageResponse
    >(functions, 'uploadWineImage');

    return remoteUploadWineImage({ image, wineId })
      .then((result) => {
        dispatch(wineSetImage(wineId, result.data.image));
        dispatch(toastShow(i18next.t('images.messages.upload-image-success')));
        return result.data;
      })
      .catch(() => {
        dispatch(
          toastShow(
            i18next.t('images.messages.upload-image-error'),
            ToastColor.ERROR
          )
        );
      })
      .finally(() => {
        dispatch(loadingHide());
      });
  };
};

export function getImagePathWithDomain(path?: string): string {
  if (path) {
    return `${imageHostUrl}v0/b/sommpad-prod.appspot.com/o/${path}`;
  }
  return '';
}
