import React, { useEffect, useState } from 'react';

import { Modal, Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const Offline: React.FC = () => {
  const [isOffline, setIsOffline] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    window.addEventListener('online', () => setIsOffline(false));
    window.addEventListener('offline', () => setIsOffline(true));

    return () => {
      window.removeEventListener('online', () => setIsOffline(false));
      window.removeEventListener('offline', () => setIsOffline(true));
    };
  }, []);

  return (
    <Modal open={isOffline}>
      <Snackbar
        open={true}
        autoHideDuration={0}
        message={t('offline.message')}
      />
    </Modal>
  );
};
