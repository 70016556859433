import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import * as serviceWorker from '../../serviceWorkerRegistration';
import { loadingShow } from '../../store/reducers/loading-reducer';
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';

export const ServiceWorkerWrapper: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showReload, setShowReload] = React.useState(false);
  const [waitingWorker, setWaitingWorker] =
    React.useState<ServiceWorker | null>(null);

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = () => {
    dispatch(loadingShow());
    setShowReload(false);
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    window.location.reload();
  };

  return (
    <ConfirmationDialog
      open={showReload}
      title={t('app.update.labels.header')}
      message={t('app.update.labels.message')}
      okHandler={reloadPage}
    />
  );
};
