import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { loadingShow, loadingHide } from '../store/reducers/loading-reducer';

export const FallbackComponent: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadingShow());
    return () => {
      dispatch(loadingHide());
    };
  }, []);

  return null;
};
