export interface KeyValue {
  id: string;
  value: number;
}

export function sortObjectPropsToArray(object: {
  [key: string]: number;
}): KeyValue[] {
  const arr: KeyValue[] = [];
  const keys = Object.keys(object);

  for (let i = 0; i < keys.length; i++) {
    const id = keys[i];
    if (object[id] > 0) {
      arr.push({
        id,
        value: object[id],
      });
    }
  }

  return arr.sort((a, b) => b.value - a.value);
}
