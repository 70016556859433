import { createTheme, ThemeOptions } from '@mui/material';

export const themeOptions: ThemeOptions = {
  palette: {
    background: {
      default: '#ffffff',
    },
    primary: {
      main: '#ffd900',
    },
    secondary: {
      main: '#cd5700',
    },
    gold: {
      main: '#c2aa00',
      contrastText: '#fff',
    },
    twitter: {
      main: '#1da1f2',
      contrastText: '#fff',
    },
    facebook: {
      main: '#4267b2',
      contrastText: '#fff',
    },
    google: {
      main: '#db4437',
      contrastText: '#fff',
    },
  },
  components: {
    MuiCard: {
      defaultProps: {
        elevation: 4,
      },
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        sx: {
          boxShadow: 'none',
        },
      },
    },
  },
};

export const theme = createTheme(themeOptions);

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: React.CSSProperties['color'];
    };
  }

  interface Palette {
    gold: Palette['primary'];
    twitter: Palette['primary'];
    facebook: Palette['primary'];
    google: Palette['primary'];
  }
  interface PaletteOptions {
    gold: PaletteOptions['primary'];
    twitter: PaletteOptions['primary'];
    facebook: PaletteOptions['primary'];
    google: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    gold: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    twitter: true;
    facebook: true;
    google: true;
  }
}
