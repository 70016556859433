import React, { useEffect } from 'react';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { User } from 'firebase/auth';
import { useDispatch } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { LoadingSpinner } from './components/LoadingSpinner/LoadingSpinner';
import { Offline } from './components/Offline/Offline';
import { ServiceWorkerWrapper } from './components/ServiceWorkerWrapper/ServiceWorkerWrapper';
import { Toast } from './components/Toast/Toast';
import { Layout } from './routing/Layout';
import { onAuthChanged } from './services/authentication-service';
import {
  dashboardSubscribe,
  dashboardUnsubscribe,
} from './services/dashboard-service';
import {
  profileSubscribe,
  profileUnsubscribe,
} from './services/profile-service';
import {
  authenticationSignedIn,
  authenticationSignedOut,
} from './store/reducers/authentication-reducer';
import { loadingHide } from './store/reducers/loading-reducer';
import { theme } from './theme/theme';

const App: React.FC = () => {
  const dispatch = useDispatch();

  // manage auth
  useEffect(() => {
    const unsubscribeAuthStateChanged = onAuthChanged(
      async (user: User | null) => {
        dispatch(loadingHide());

        if (user) {
          dashboardSubscribe(user.uid, dispatch);
          profileSubscribe(user.uid, dispatch);
          dispatch(authenticationSignedIn(user));
        } else {
          dashboardUnsubscribe();
          profileUnsubscribe(dispatch);
          dispatch(authenticationSignedOut());
        }
      }
    );
    return unsubscribeAuthStateChanged;
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LoadingSpinner />
      <Toast />
      <BrowserRouter>
        <Layout />
      </BrowserRouter>
      <Offline />
      <ServiceWorkerWrapper />
    </ThemeProvider>
  );
};

export default App;
