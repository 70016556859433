import { createSelector } from 'reselect';

import { WineInterface } from '../../models/wine';
import { ReduxState, ReduxAction } from '../store';
import { createAction } from '../utils';

import { AUTHENTICATION_ACTION_TYPES } from './authentication-reducer';

export type WineMap = { [key: string]: WineInterface };
export interface WineReduxState {
  isLoaded: boolean;
  isLoading: boolean;
  wines: WineMap;
  wineOrder: Array<string>;
}

const INITIAL_STATE: WineReduxState = {
  isLoaded: false,
  isLoading: false,
  wines: {},
  wineOrder: [],
};

const WINE_LIST_ACTION_TYPES = {
  WINE_LIST_DATA_LOADING: 'WINE_LIST_ACTIONS/LOADING',
  WINE_LIST_DATA_LOADED: 'WINE_LIST_ACTIONS/LOADED',
  WINE_LIST_DATA_ERROR: 'WINE_LIST_ACTIONS/ERROR',
  WINE_LIST_SET_WINE: 'WINE_LIST_ACTIONS/SET_WINE',
  WINE_LOADED: 'WINE_LIST_ACTIONS/WINE_LOADED',
  SET_WINE_IMAGE: 'WINE_LIST_ACTIONS/SET_WINE_IMAGE',
  ADD_WINE: 'WINE_LIST_ACTIONS/ADD_WINE',
  DELETE_WINE: 'WINE_LIST_ACTIONS/DELETE_WINE',
};

export function wineAdd(id: string): ReduxAction {
  return createAction(WINE_LIST_ACTION_TYPES.ADD_WINE, {
    id,
  });
}

export function wineDelete(id: string): ReduxAction {
  return createAction(WINE_LIST_ACTION_TYPES.DELETE_WINE, {
    id,
  });
}

export function wineSetData(id: string, data: WineInterface): ReduxAction {
  return createAction(WINE_LIST_ACTION_TYPES.WINE_LIST_SET_WINE, {
    id,
    data,
  });
}

export function wineSetImage(id: string, data: string): ReduxAction {
  return createAction(WINE_LIST_ACTION_TYPES.SET_WINE_IMAGE, {
    id,
    data,
  });
}

export const wineStoreSelector = (state: ReduxState): WineReduxState =>
  state.wine || INITIAL_STATE;

export const wineStoreWineSelector =
  (id: string) =>
  (state: ReduxState): WineInterface | undefined =>
    wineStoreSelector(state).wines[id];

export const wineListSelector = createSelector(
  [wineStoreSelector],
  ({ wines, wineOrder = [], isLoading, isLoaded }: WineReduxState) => ({
    isLoaded,
    isLoading,
    wines: wineOrder.map((id: string) => ({ id, ...wines[id] })),
  })
);

export function wineListDataLoading(): ReduxAction {
  return createAction(WINE_LIST_ACTION_TYPES.WINE_LIST_DATA_LOADING);
}

export function wineListDataLoaded(
  wines: WineMap,
  wineOrder: Array<string>
): ReduxAction {
  return createAction(WINE_LIST_ACTION_TYPES.WINE_LIST_DATA_LOADED, {
    wines,
    wineOrder,
  });
}

export function wineDataLoaded(id: string, wine: WineInterface): ReduxAction {
  return createAction(WINE_LIST_ACTION_TYPES.WINE_LOADED, {
    id,
    wine,
  });
}

export const wineReducer = (
  state = INITIAL_STATE,
  action: ReduxAction
): WineReduxState => {
  switch (action.type) {
    case WINE_LIST_ACTION_TYPES.WINE_LIST_DATA_LOADING:
      return { ...state, isLoading: true };
    case WINE_LIST_ACTION_TYPES.WINE_LIST_DATA_LOADED:
      const { wines, wineOrder } = action.payload;
      const currentWineOrder = state.wineOrder;
      const currentWines = state.wines;

      const wineKeys = Object.keys(wines);

      for (let i = 0; i < wineKeys.length; i++) {
        currentWines[wineKeys[i]] = wines[wineKeys[i]];
        currentWineOrder.push(wineOrder[i]);
      }

      return {
        ...state,
        wines: currentWines,
        wineOrder: currentWineOrder,
        isLoaded: true,
        isLoading: false,
      };
    case WINE_LIST_ACTION_TYPES.WINE_LOADED: {
      const { wine, id } = action.payload;
      return {
        ...state,
        wines: {
          ...state.wines,
          [id]: {
            ...wine,
          },
        },
      };
    }
    case WINE_LIST_ACTION_TYPES.ADD_WINE: {
      const { id } = action.payload;
      return {
        ...state,
        wines: {
          ...state.wines,
          [id]: {},
        },
        wineOrder: [id, ...state.wineOrder],
      };
    }
    case WINE_LIST_ACTION_TYPES.DELETE_WINE: {
      const { id } = action.payload;
      return {
        ...state,
        wines: {
          ...state.wines,
          [id]: undefined,
        },
        wineOrder: state.wineOrder.filter((value) => value !== id),
      };
    }
    case WINE_LIST_ACTION_TYPES.WINE_LIST_SET_WINE: {
      const {
        id,
        data: { appearance, palate, detail, nose },
      } = action.payload;
      return {
        ...state,
        wines: {
          ...state.wines,
          [id]: {
            ...state.wines[id],
            appearance,
            palate,
            detail,
            nose,
          },
        },
      };
    }
    case WINE_LIST_ACTION_TYPES.SET_WINE_IMAGE: {
      const { id, data } = action.payload;

      return {
        ...state,
        wines: {
          ...state.wines,
          [id]: {
            ...state.wines[id],
            image: data,
          },
        },
      };
    }
    case AUTHENTICATION_ACTION_TYPES.SIGNED_OUT: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};
