import React from 'react';

import { Drawer, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { Menu } from '../components/Menu';
import { authenticationSelector } from '../store/reducers/authentication-reducer';
import { isMenuOpenSelector, menuHide } from '../store/reducers/menu-reducer';

import { Routing } from './Routes';

const drawerWidth = 300;

export const Layout: React.FC = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(isMenuOpenSelector);
  const closeMenu = () => dispatch(menuHide());

  const { isAuthenticationChecked, isAuthenticated } = useSelector(
    authenticationSelector
  );

  if (!isAuthenticationChecked) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      {isAuthenticated && (
        <>
          <Box sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }}>
            <Drawer
              open={isOpen}
              onBackdropClick={closeMenu}
              variant="temporary"
              onClose={closeMenu}
              sx={{
                display: { xs: 'block', lg: 'none' },
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: drawerWidth,
                },
              }}
            >
              <Menu />
            </Drawer>
          </Box>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', lg: 'block' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
              },
            }}
            open
          >
            <Menu />
          </Drawer>
        </>
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: {
            lg: isAuthenticated ? `calc(100% - ${drawerWidth}px)` : '100%',
          },
        }}
      >
        <Routing />
      </Box>
    </Box>
  );
};
