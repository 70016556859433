export const menuHeaderContainerStyle = {
  backgroundColor: 'primary.main',
  padding: 2,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

export const menuAvatarStyle = {
  width: 100,
  height: 100,
  mr: 1,
  border: '1px solid #bbb',
};
