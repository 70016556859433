import React, { Suspense } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import { FallbackComponent } from './FallbackComponent';
import { ProtectedRoute } from './ProtectedRoute';
import { ROUTE_PATHS } from './route-paths';

// pages
const AccountDeletionPolicyPage = React.lazy(
  () => import('../pages/AccountDeletionPolicy/AccountDeletionPolicy')
);
const DashboardPage = React.lazy(() => import('../pages/Dashboard/Dashboard'));
const PrivacyPolicyPage = React.lazy(
  () => import('../pages/PrivacyPolicy/PrivacyPolicy')
);
const ProfilePage = React.lazy(() => import('../pages/Profile/Profile'));
const SettingsPage = React.lazy(() => import('../pages/Settings/Settings'));
const SignInPage = React.lazy(() => import('../pages/SignIn/SignIn'));
const SignUpPage = React.lazy(() => import('../pages/SignUp/SignUp'));
const WineEditPage = React.lazy(() => import('../pages/WineEdit/WineEdit'));
const WineListPage = React.lazy(() => import('../pages/WineList/WineList'));
const WineViewPage = React.lazy(() => import('../pages/WineView/WineView'));

export const Routing: React.FC = () => {
  return (
    <Suspense fallback={<FallbackComponent />}>
      <Routes>
        <Route
          path={ROUTE_PATHS.DASHBOARD}
          element={<ProtectedRoute outlet={<DashboardPage />} />}
        />
        <Route
          path={ROUTE_PATHS.PROFILE}
          element={<ProtectedRoute outlet={<ProfilePage />} />}
        />
        <Route
          path={ROUTE_PATHS.SETTINGS}
          element={<ProtectedRoute outlet={<SettingsPage />} />}
        />
        <Route
          path={ROUTE_PATHS.WINE_LIST}
          element={<ProtectedRoute outlet={<WineListPage />} />}
        />
        <Route
          path={`${ROUTE_PATHS.WINE_EDIT}/:id`}
          element={<ProtectedRoute outlet={<WineEditPage />} />}
        />
        <Route
          path={`${ROUTE_PATHS.WINE_VIEW}/:id`}
          element={<ProtectedRoute outlet={<WineViewPage />} />}
        />
        <Route
          path={ROUTE_PATHS.ACOUNT_DELETION_POLICY}
          element={<AccountDeletionPolicyPage />}
        />
        <Route
          path={ROUTE_PATHS.PRIVACY_POLICY}
          element={<PrivacyPolicyPage />}
        />
        <Route path={ROUTE_PATHS.SIGN_IN} element={<SignInPage />} />
        <Route path={ROUTE_PATHS.SIGN_UP} element={<SignUpPage />} />
        <Route
          path="*"
          element={<Navigate to={ROUTE_PATHS.SIGN_IN} replace />}
        />
      </Routes>
    </Suspense>
  );
};
