import {
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onIdTokenChanged,
  signInWithPopup,
  signOut,
  User,
  Unsubscribe,
  deleteUser,
} from 'firebase/auth';
import i18next from 'i18next';
import { Dispatch } from 'redux';

import { auth } from '..';
import { loadingShow, loadingHide } from '../store/reducers/loading-reducer';
import { ToastColor, toastShow } from '../store/reducers/toast-reducer';

export function signInWithCredentials(email: string, password: string) {
  return async function (dispatch: Dispatch): Promise<boolean> {
    try {
      dispatch(loadingShow());
      await signInWithEmailAndPassword(auth, email, password);
      return true;
    } catch (error) {
      dispatch(loadingHide());
      dispatch(
        toastShow(i18next.t('auth.messages.sign-in-error'), ToastColor.ERROR)
      );
      return false;
    }
  };
}

export function signUpWithCredentials(email: string, password: string) {
  return async function (dispatch: Dispatch): Promise<boolean> {
    try {
      dispatch(loadingShow());
      await createUserWithEmailAndPassword(auth, email, password);
      return true;
    } catch (error: unknown) {
      dispatch(loadingHide());
      if (error instanceof Error) {
        dispatch(
          toastShow(
            `${i18next.t('auth.messages.sign-up-error')}: ${error.message}`,
            ToastColor.ERROR
          )
        );
      }

      return false;
    }
  };
}

export function signInWithGoogle() {
  return async function (dispatch: Dispatch): Promise<boolean> {
    try {
      dispatch(loadingShow());
      await signInWithPopup(auth, new GoogleAuthProvider());
      return true;
    } catch (error) {
      dispatch(loadingHide());
      dispatch(
        toastShow(i18next.t('auth.messages.sign-in-error'), ToastColor.ERROR)
      );
      return false;
    }
  };
}

export async function logout(dispatch: Dispatch): Promise<void> {
  try {
    await signOut(auth);
    dispatch(toastShow(i18next.t('auth.messages.sign-out-success')));
  } catch (error) {
    dispatch(
      toastShow(i18next.t('auth.messages.sign-error-success'), ToastColor.ERROR)
    );
  }
}

export function deleteAccount() {
  if (auth.currentUser) {
    return deleteUser(auth.currentUser);
  }
  return Promise.reject();
}

export function getAuthToken(): Promise<string> | undefined {
  return auth.currentUser?.getIdToken();
}

export function onAuthChanged(
  callback: (user: User | null) => void
): Unsubscribe {
  return onAuthStateChanged(auth, callback);
}

export function onUserTokenChanged(
  callback: (user: User | null) => void
): Unsubscribe {
  return onIdTokenChanged(auth, callback);
}
